<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar leftButton="arrow-back" [leftIcon]="true" rightButton="settings" [rightIcon]="true" [title]="title"
    (leftClick)="onFinishedClick(false)" (rightClick)="showOptions()" [loading]="loading"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <!-- (swipe)="swipeEvent($event)" -->
  <div class="view-frame">
    <!-- bg-filter-1-light -->
    <div class="bg-container bg-container-fill" *ngIf="hasParams && pageOne"
      [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: photoUrl ? photoUrl : null}">

      <div class="content">
        <!-- content-margin-top -->
        <div fxLayout="column" class="content-height no-scroll" *ngIf="activityContainer">
          <!-- <div fxLayout="column"> -->

          <div
            class="scrollbar-y content-height margin-horizontal-s margin-top-s text-color-overlay scroll-into-view-container"
            fxLayout="column" (scroll)="onUserScoll($event)" #scrollContent>

            <!-- text-div-margin -->
            <div class="text-left" [ngClass]="inputFocused ? 'scroll-padding' : ''">

              <!-- class="text-div-margin-s" -->

              <div class="center-text" *ngIf="!questFocused">
                <activity-progress-data [activityContainer]="activityContainer" [showExtra]="showExtra"
                  [update]="updateCharts" [flat]="true" [active]="inProgress && isNotChecked"></activity-progress-data>

                <activity-progress-data *ngIf="withNavInfo && activityNavContainer"
                  [activityContainer]="activityNavContainer" [showExtra]="showExtra" [update]="updateCharts"
                  [flat]="true" [active]="inProgress && isNotChecked">
                </activity-progress-data>
              </div>

              <ion-row class="center-text margin-top-s margin-bottom-none padding-horizontal-s">
                <ion-col size="12" class="margin-vertical-none">
                  <span class="info-text-m text-color-primary">{{levelDisp}}</span>
                </ion-col>
              </ion-row>

              <ion-row class="center-items padding-horizontal-s">
                <ion-col size="2" class="margin-vertical-none">
                  <!-- <ion-button [disabled]="!locationURL" class="action-button-fill button-color-transparent"
                    (click)="openURL(locationURL)">
                    <icon-wrapper [icon]="appIcons.googleMaps" [custom]="true"></icon-wrapper>
                  </ion-button> -->
                  <icon-wrapper [icon]="appIcons.googleMaps" [custom]="true"></icon-wrapper>
                </ion-col>
                <ion-col size="10" class="margin-vertical-none">
                  <span class="info-text-m text-color-primary">{{locationViewName}}</span>
                </ion-col>
              </ion-row>

              <ion-row class="center-items padding-horizontal-s padding-bottom-s">
                <ion-col size="2" class="margin-vertical-none">
                  <!-- <ion-button class="action-button-fill button-color-transparent" (click)="viewTutorial()">
                    <icon-wrapper [icon]="icons.activity.icon" [custom]="icons.activity.custom"></icon-wrapper>
                  </ion-button> -->
                  <icon-wrapper [icon]="icons.activity.icon" [custom]="icons.activity.custom"></icon-wrapper>
                </ion-col>
                <ion-col size="10" class="margin-vertical-none">
                  <div [innerHTML]="activityDescription" class="info-text-m description-frame text-left">
                  </div>
                </ion-col>
              </ion-row>

              <ion-row class="center-items padding-horizontal-s padding-bottom-s"
                *ngIf="hasActivityNav && dispActivityNav">
                <ion-col size="2" class="margin-vertical-none">
                  <icon-wrapper [icon]="icons.activityNav.icon" [custom]="icons.activityNav.custom"></icon-wrapper>
                </ion-col>
                <ion-col size="10" class="margin-vertical-none">
                  <div [innerHTML]="activityNavDescription" class="info-text-m description-frame text-left">
                  </div>
                </ion-col>
              </ion-row>

              <ion-row class="center-items padding-horizontal-s padding-bottom-s"
                *ngIf="hasActivityFinish && dispActivityFinish">
                <ion-col size="2" class="margin-vertical-none">
                  <icon-wrapper [icon]="icons.activityFinish.icon"
                    [custom]="icons.activityFinish.custom"></icon-wrapper>
                </ion-col>
                <ion-col size="10" class="margin-vertical-none">
                  <div [innerHTML]="activityFinishDescription" class="info-text-m description-frame text-left">
                  </div>
                </ion-col>
              </ion-row>

              <audio-guide *ngIf="withAudioGuide" label="" [content]="descriptionPlain" [autoplay]="audioGuideAutoplay"
                (status)="updateAudioGuideStatus($event)"></audio-guide>

              <safe-html-renderer [imageWrapper]="true" [unsafeHtmlContent]="descriptionView"
                innerClass="info-text-m description-frame text-left padding-horizontal-s"
                innerNgClass="{'center-text': centerDescription}"></safe-html-renderer>

              <div *ngIf="videoUrl" class="margin-top-s margin-bottom-xs">
                <youtube-view [videoUrl]="videoUrl"
                  [customClass]="!isNavPreview ? 'height-40' : 'height-20'"></youtube-view>
              </div>

              <!-- <div tappable class="padding-xs text-center">
                <span class="as-text element-selector button-font-size-m text-color-primary"
                  (click)="showActivityTutorial()">{{showTutorialLabel}}</span>
              </div> -->

              <div *ngIf="showPhotoCard && hasPhotoUpload && (!isNavPreview || showTestPreview)"
                class="padding-bottom-s padding-top-s">
                <nav-bar [slim]="true" [slidesPerView]="navBarSlidesPerView" [(selectedTabId)]="photoCardSelected"
                  [navBarItems]="navBarPhotoCardItems" (select)="selectPhotoCardFromTab($event)">
                </nav-bar>
              </div>

              <div *ngIf="showPhotoCard && (!isNavPreview || showTestPreview)" class="text-center"
                [ngClass]="{'padding-s': !hasPhotoUpload, 'img-small-fixed-container' : hasPhotoUpload}">
                <span class="info-text-mlg text-color-primary" *ngIf="!hasPhotoUpload">{{photoCardLabel}}</span>
                <responsive-img [photoUrl]="photoCardUrl" class="border-radius img-small" innerClass="padding-top-s"
                  [withUpload]="showPhotoUploadMode" (upload)="uploadNewPhoto()" (zoom)="viewPhotoCard()"
                  [fixedZoom]="true">
                </responsive-img>
              </div>

              <div *ngIf="showMultiPhotoCard && (!isNavPreview || showTestPreview)" class="text-center">
                <grid-photo-uploads [data]="multiPhotoCards" [enable]="inProgress" emptyMessage="No photos available"
                  [reload]="updatePhotoGrid" (action)="uploadMultiPhoto($event)"></grid-photo-uploads>
              </div>

              <!-- <ion-row *ngIf="hasPhotoUpload && (!isNavPreview || showTestPreview)"
                class="center-items padding-horizontal-s">
                <ion-col size="2" class="margin-vertical-none">
                  <ion-button class="action-button-fill button-color-transparent" (click)="validateButtonClick()">
                    <icon-wrapper
                      [icon]="validateRegistered ? (validateStatus ? appIconsStandard.accept : appIconsStandard.reject) : appIconsStandard.refresh"
                      [custom]="false"></icon-wrapper>
                  </ion-button>
                </ion-col>
                <ion-col size="10" class="margin-vertical-none">
                  <div *ngIf="validateDisp && showPhotoCard" [innerHTML]="validateDisp"
                    class="info-text-m text-left description-frame">
                  </div>
                </ion-col>
              </ion-row> -->

              <div *ngIf="hasVideoUpload && (!isNavPreview || showTestPreview)" class="padding-top-s text-center">
                <span class="info-text-mlg text-color-primary" *ngIf="!hasPhotoUpload">{{mediaCardLabel}}</span>
                <media-recording-video [videoUrl]="videoUploadUrl" [context]="mediaUploadContext"
                  [toggleRecording]="mediaRecordControl" (mediaRecord)="onMediaRecord($event)"
                  (mediaUpload)="onMediaUpload()" innerClass="padding-top-s"></media-recording-video>
              </div>

              <div *ngIf="hasAudioUpload && (!isNavPreview || showTestPreview)" class="padding-top-s text-center">
                <span class="info-text-mlg text-color-primary" *ngIf="!hasPhotoUpload">{{mediaCardLabel}}</span>
                <media-recording-audio [audioUrl]="audioUploadUrl" [context]="mediaUploadContext"
                  [toggleRecording]="mediaRecordControl" (mediaRecord)="onMediaRecord($event)"
                  (mediaUpload)="onMediaUpload()" innerClass="padding-top-s"></media-recording-audio>
              </div>

              <div *ngIf="questData && showQuestData && (!isNavPreview || showTestPreview)">
                <!-- text-color-overlay -->
                <quest-item [dframe]="true" [ownButton]="true" [data]="questData" [shake]="shake"
                  [showAnswers]="checkQuestComplete()" [loading]="checkLoading"
                  (submitResponse)="submitResponse($event)" (check)="checkInput()" (action)="questAction($event)"
                  (inputFocus)="questFocus($event)">
                </quest-item>
              </div>

              <!-- <div *ngIf="descriptionFooterCompact" class="theme-border-top margin-vertical-s"></div> -->

              <!-- <div *ngIf="descriptionFooterCompact" class="center-text padding-s">
                <div [innerHTML]="descriptionFooterCompact"
                  class="info-text-m description-frame item-color-overlay-alternate padding-s text-center">
                </div>
              </div> -->

              <div *ngIf="descriptionFooterCompact" [innerHTML]="descriptionFooterCompact"
                class="info-text-m description-frame bold padding-s text-center">
              </div>

              <div tappable class="padding-xs text-center" *ngIf="enablePrestart" (click)="prestart()">
                <span
                  class="as-text element-selector button-font-size-mlg text-color-primary">{{descriptionPrestart}}</span>
              </div>

              <div *ngIf="activityContainer.enable.retry" [innerHTML]="descriptionRetry"
                class="info-text-m description-frame bold padding-s text-center">
              </div>

              <activity-details-entry [data]="descriptionParams" [isNavPreview]="isNavPreview" [isPreview]="!inProgress"
                [min]="(isNavPreview || !inProgress) && enablePrestart" [gridOnly]="true">
              </activity-details-entry>

            </div>

            <!-- scrollbar-y item-slide-height  -->
            <!-- <div *ngIf="hasParamSlides" class="theme-background theme-border-bottom theme-border-top margin-top-s">
              <div>
                <dynamic-slides [slideContent]="paramSlides" type="activityParam" [slidesPerView]="1"></dynamic-slides>
              </div>
            </div> -->

          </div>

          <div fxFlex></div>
          <!-- stick-bottom -->
          <div class="padding-s" *ngIf="!withCustomButtons">

            <div *ngIf="activityContainer.enable && activityContainer.enable.return">
              <ion-button class="action-button-fill button-color-accent" (click)="onFinishedClick(true)">
                <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIconsStandard.map"></icon-wrapper>
                <span class="button-font-size-s">Return</span>
              </ion-button>
            </div>

            <div *ngIf="activityContainer.enable && activityContainer.enable.skip" class="padding-xs">
              <ion-button class="action-button-fill button-color-alternate" [ngClass]="{'button-grey': !buttonActive}"
                (click)="skipWizard()">
                <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIconsStandard.skipForward">
                </icon-wrapper>
                <span class="button-font-size-s">Skip</span>
              </ion-button>
            </div>

            <div *ngIf="activityContainer.enable && activityContainer.enable.retry" class="padding-xs">
              <ion-button class="action-button-fill button-color-alternate" (click)="validatePhoto(true, false)"
                [disabled]="validationInProgress">
                <icon-wrapper class="slot-icon-padding" slot="start" icon="camera"></icon-wrapper>
                <span class="button-font-size-s">Retry</span>
              </ion-button>
            </div>

            <div *ngIf="activityContainer.enable && activityContainer.enable.photo" class="padding-xs">
              <ion-button class="action-button-fill button-color-alternate" (click)="validatePhoto(false, false)"
                [disabled]="validationInProgress">
                <icon-wrapper class="slot-icon-padding" slot="start" icon="camera"></icon-wrapper>
                <span class="button-font-size-s">Take photo</span>
              </ion-button>
            </div>

            <div *ngIf="activityContainer.enable && activityContainer.enable.record">
              <ion-button class="action-button-fill button-color-alternate"
                [disabled]="(activityContainer.disabled && activityContainer.disabled.record) || mediaRecordStateLocked"
                (click)="toggleRecording()">
                <icon-wrapper class="slot-icon-padding" slot="start"
                  [icon]="!mediaRecordState ? appIconsStandard.record : appIconsStandard.stop"></icon-wrapper>
                <span>Record</span>
              </ion-button>
            </div>

            <div *ngIf="activityContainer.enable && activityContainer.enable.scan" class="padding-xs">
              <ion-button
                [disabled]="(activityContainer.disabled && activityContainer.disabled.scan) || validationInProgress"
                class="action-button-fill button-color-alternate" (click)="scanBarcodeOnClick()">
                <icon-wrapper class="slot-icon-padding" slot="start" [custom]="true" [icon]="appIcons.qr">
                </icon-wrapper>
                <span class="button-font-size-s">Scan</span>
              </ion-button>
            </div>

            <div *ngIf="activityContainer.enable && activityContainer.enable.map" class="padding-xs">
              <ion-button class="action-button-fill button-color-accent" (click)="onFinishedClick(true)">
                <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIconsStandard.map"></icon-wrapper>
                <span class="button-font-size-s">Continue</span>
              </ion-button>
            </div>

            <div *ngIf="activityContainer.enable && activityContainer.enable.finish" class="padding-xs">
              <ion-button class="action-button-fill button-color-alternate" (click)="onMaybeFinishedClick(true)">
                <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.forward"
                  [custom]="true"></icon-wrapper>
                <span class="button-font-size-s">Finish</span>
              </ion-button>
            </div>

            <div *ngIf="activityContainer.enable && activityContainer.enable.continue" class="padding-xs">
              <!-- <ion-button class="action-button-fill button-color-accent" (click)="onFinishedClick(true)">
                <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.check"
                  [custom]="true"></icon-wrapper>
                <span class="button-font-size-s">Continue</span>
              </ion-button> -->

              <blink-button theme="accent" name="Continue" defaultClass="button-rounded button-color-accent"
                highClass="button-rounded button-color-alternate" [icon]="appIcons.check" [customIcon]="true"
                [setTimeout]="true" (select)="onFinishedClick(true)" [noBorder]="false"></blink-button>

            </div>

            <div *ngIf="activityContainer.enable && activityContainer.enable.navigate" class="padding-xs">
              <ion-button class="action-button-fill button-color-accent" (click)="onFinishedClick(true)">
                <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.forward"
                  [custom]="true"></icon-wrapper>
                <span class="button-font-size-s">Proceed</span>
              </ion-button>
            </div>

            <div *ngIf="activityContainer.enable && activityContainer.enable.start">
              <ion-button *ngIf="!isRetry" class="action-button-fill button-color-accent"
                (click)="onFinishedClick(true)">
                <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIconsStandard.play"></icon-wrapper>
                <span class="button-font-size-s">Start</span>
              </ion-button>
              <ion-button *ngIf="isRetry" class="action-button-fill button-color-alternate"
                (click)="onFinishedClick(true)">
                <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIconsStandard.play"></icon-wrapper>
                <span class="button-font-size-s">Retry</span>
              </ion-button>
            </div>

          </div>

          <div *ngIf="withCustomButtons" class="padding-s">
            <ion-row>
              <!-- dynamic button grid -->
              <ion-col *ngFor="let b of buttons" [dynamicCol]="b.size">
                <ion-button *ngIf="b.enabled" [disabled]='b.disabled' [ngClass]="b.class" (click)="customButtonTap(b)">
                  <span class="button-font-size-s">{{b.name}}</span>
                  <icon-wrapper class="slot-icon-padding" slot="start" [icon]="b.icon" [custom]="b.customIcon">
                  </icon-wrapper>
                  <!-- <icon-wrapper [custom]="b.customIcon" slot="icon-only" [icon]="b.icon"></icon-wrapper> -->
                </ion-button>
              </ion-col>
            </ion-row>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="hasParams && !pageOne">
      <activity-details-entry [data]="descriptionParams" [isNavPreview]="isNavPreview" [isPreview]="!inProgress"
        (action)="onFinishedClick(false)">
      </activity-details-entry>
    </div>
  </div>

</ion-content>